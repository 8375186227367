import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { NavigatorService } from '@boa/app/_shared/services/navigator.service'

@Injectable()
export class AuthGuard {
  constructor(
    private navigator: NavigatorService,
    private cookieService: CookieService
  ) {}

  checkAuth() {
    const accessTokenId = this.cookieService.get('accessTokenId')
    const currentUserId = this.cookieService.get('currentUserId')

    if (accessTokenId && currentUserId) {
      return true
    } else {
      this.navigator.navigateToLogin()
      return false
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkAuth()
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state)
  }
}
