import { Injectable } from '@angular/core'
import jwtDecode from 'jwt-decode'

import { environment as ENV } from '@boa/app/../environments/environment'
import { AlertService } from './alert.service'
import { GoogleUser } from '../types/user.model'

@Injectable()
export class GoogleAuthService {
  private CLIENT_ID: string
  private googleClient

  public isAvailable: null | boolean = null

  constructor(private alertService: AlertService) {
    this.CLIENT_ID = ENV.GOOGLE_CLIENT_ID
  }

  public loadGoogleApi(callback: any): void {
    if (this.isAvailable) {
      callback({ message: 'Google API already loaded' })
      return
    }

    const node = document.createElement('script')

    node.src = 'https://accounts.google.com/gsi/client'
    node.type = 'text/javascript'
    node.async = true
    node.defer = true

    node.onload = () => {
      this.isAvailable = true

      return callback({
        message: 'Google API loaded',
      })
    }

    node.onerror = () => {
      this.isAvailable = false

      return callback({
        error: 'Failed to load Google API',
      })
    }

    document.getElementsByTagName('head')[0].appendChild(node)
  }

  public loadAuth2(
    element: any,
    callback: (googleResponse: any) => void
  ): void {
    this.loadGoogleApi((res) => {
      if (res && !res.error) {
        if (window?.google?.accounts) {
          if (!this.googleClient) {
            this.googleClient = window.google.accounts.id.initialize({
              client_id: this.CLIENT_ID,
              callback,
            })
          }
          window.google.accounts.id.renderButton(element, {
            theme: 'outline',
            size: 'medium',
          })
        }
      } else {
        this.alertService.pushAlert({
          type: 'danger',
          message: `Login with Google failed. ${(res || { error: '' }).error}`,
        })
      }
    })
  }

  renderSignIn(element: any, callback: (user: GoogleUser) => void): void {
    this.loadAuth2(element, (response) => {
      const user = this.getUserData(response?.credential)
      if (!user) {
        this.alertService.pushAlert({
          type: 'danger',
          message: 'Login with Google failed. User not found.',
        })
      }
      callback(user)
    })
  }

  private getUserData(credential: any): GoogleUser {
    if (credential) {
      const decodedCredential: any = jwtDecode(credential)
      return {
        email: decodedCredential.email,
        googleId: decodedCredential.sub,
        googleIdToken: credential,
      }
    }
  }
}
